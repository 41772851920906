import { useEffect } from 'react';

import { useUnload } from '../hooks/useUnload';

export function releaseVideoElement(video: HTMLVideoElement | null): void {
  if (!video) return;
  video.src = '';
  video.srcObject = null;
}

export function useReleaseVideoOnUnmount(
  videoRef: React.MutableRefObject<HTMLVideoElement | null>,
  enabled: boolean,
  onBeforeRelease?: (video: HTMLVideoElement) => Promise<void> | void
): void {
  useUnload(() => {
    const video = videoRef.current;
    video && onBeforeRelease && onBeforeRelease(video);
  });
  useEffect(() => {
    // Cleanup video element on unmount to avoid leaking media players
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1144736
    const video = videoRef.current;
    return () => {
      if (video && enabled) {
        onBeforeRelease && onBeforeRelease(video);
        releaseVideoElement(video);
      }
    };
  }, [enabled, onBeforeRelease, videoRef]);
}
