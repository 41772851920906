import { useBeforeUnload, useBlocker } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';

// TODO: this API is unreliable on mobile. Expand this to
// https://github.com/GoogleChromeLabs/page-lifecycle instead.

function useUnload<Handler extends (ev: BeforeUnloadEvent) => unknown>(
  fn: Handler
): void {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const aborter = new AbortController();
    window.addEventListener(
      'beforeunload',
      (ev) => {
        return cb.current?.(ev);
      },
      { signal: aborter.signal }
    );

    return () => aborter.abort();
  }, []);
}

function useIsUnloading(): boolean {
  const [isUnloading, setIsUnloading] = useState(false);
  useUnload(() => {
    setIsUnloading(true);
  });
  return isUnloading;
}

export function useBlockLeave(
  canLeave: () => boolean,
  message = 'Are you sure to leave this page, changes you made may not be saved.'
): void {
  useBeforeUnload((ev) => {
    if (!canLeave()) {
      ev.preventDefault();
    }
  });

  useBlocker(() => {
    if (!canLeave()) {
      return !window.confirm(message);
    }
    return false;
  });
}

export { useUnload, useIsUnloading };
